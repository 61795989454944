#menu-button2 {
    /* background-color: white; */
    color: white;
    font-family: "Noto Serif Display", serif;
    z-index: 2;
}

.menu-button2 {
    padding-right: 7vh;
    display: none;
}

.menu-button2 > .MuiBox-root > .hamburger-react {
    color: white;
}


#list-item {
    font-family: "Noto Serif Display", serif;
    font-size: 20px;
}

#list-item:hover {
    background-color: #7ACCC8;
    ;
}

.hamburger-react {
    color: black;
}

/* .css-pcaj6y-JoyDrawer-content {
    background-color: rgba(255, 255, 255, 0.8) !important;
} */

@media only screen and (max-width: 1170px){
    .hamburger-react {
        /* width: 5vh !important; */
    }
}

@media only screen and (max-width: 570px){
    .hamburger-react {
        /* width: 5vh !important; */
    }
}


@media only screen and (max-width: 430px){
    .hamburger-react {
        width: 2vh !important;
    }
}

@media only screen and (max-width: 1170px){

    .menu-button2 {
        display: flex;
        visibility: visible;
    }
}