@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
* {
    /* font-family: "Crimson Text", serif; */
    /* font-family: "Noto Serif Display", serif; */
    font-family: "Manrope", sans-serif;
    letter-spacing: .5px;
  }

.header-phone {
    display: flex;
    align-items: center;
    gap: 6px;
}

.header-phone3 {
    display: flex;
    align-items: center;
    gap: 6px;
}

.header-phone > a {
    color: white !important;
}

.header-phone3 > a {
    color: black !important;
}

#phone {
    font-size: 23px;
    color: white;
}

#phone3 {
    font-size: 23px;
    color: black;
}


#request-appointment-button {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .8px;
    padding: 1vh 3vh 1vh 3vh;

    /* background-color: #7ACCC8; */

    border: none;
    border-radius: 5px;
    background-color: #7CCCC7;
    /* background: linear-gradient(to bottom, rgba(122, 202, 194, 1), rgba(95, 192, 221, 0.4)); */
    /* border: solid; */
    border-width: 1px;
    border-color: #1bdcd2;
    border-radius: 5px;
    transition: all .5s;
}

@media only screen and (max-width: 430px) {
    #request-appointment-button {
        font-size: 10px;
    }

    #phone {
        font-size: 16px;
    }

    #phone3 {
        font-size: 16px;
    }

    .header-phone > a {
        font-size: 14px;
    }

    .header-phone3 > a {
        font-size: 14px;
    }
}

#request-appointment-button:hover {
    background-color: #93dbd6;
}

#office-hours-button {
    font-family: "Manrope", sans-serif;
    color: white;
    letter-spacing: .8px;
    padding: 1vh 3vh 1vh 3vh;

    border: none;
    border-radius: 5px;
    background-color: #155990;
    /* background: linear-gradient(to bottom, rgba(12, 53, 86, 1), rgba(135, 188, 204, 0.4)); */
    /* border: solid; */
    border-width: 1px;
    border-color: #1bdcd2;
    border-radius: 5px;
    transition: all .5s;
}

@media only screen and (max-width: 677px) {
    #office-hours-button {
        display: none;
    }
}

#office-hours-button:hover {
    background-color: #1763a0;
}

.footer-hours-dropdown {
    padding: 0vh 4vh 0vh 4vh;
}

a {
    font-family: "Manrope", sans-serif;
    text-decoration: none;
    color: black;
    font-size: 18px;
}

.background {
    background-color:#F5F4F0;
    margin-top: -5px;
}

/* @media only screen and (min-width: 1200px) {
    .background {
        display: flex;
        justify-content: space-between;
    }
    
    .large-monitor2 {
        width: 1100px;
    }

    .background3 {
        display: flex;
        align-items: center;
    }
    
} */

.background2 {
    z-index: 0;
    background-color: transparent;
    padding: 0vh 3vh 0vh 3vh;
}

.background3 {
    display: flex;
    flex-direction: column;
    z-index: 0;
    background-color:#F5F4F0;
    padding: 0vh 3vh 0vh 3vh;
}

.background2::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 79vh;
    /* bottom: 89.6vh; */
    left: 0;
    background: linear-gradient(to bottom, rgb(49, 49, 49), rgba(57, 57, 57, 0.001));
    /* background: rgba(0, 0, 0, 0.2); */
    transition: all 0.28s ease-in-out;
    z-index: -1; /* Ensure it is below the h3 element */
}

@media only screen and (min-width: 1260px) {
    .background2::before {
        bottom: 70vh;
    }
}

.contact-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 1vh 3vh 1vh 3vh;
    height: 50px;
    /* background-color: white; */
    border-radius: 20px;
}

@media only screen and (max-width: 570px) {
    .contact-top {
        padding: 0vh 1vh 0vh 0vh;
    }

    hr {
        display: none;
    }
}

.contact-top > h3{
    font-family: "Noto Serif Display", serif;
}

.contact-top2 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact-top2 > button{
    text-align: center;
    padding: 1vh;
    border: none;
    background-color: #7ACCC8;
    font-family: "Noto Serif Display", serif;
    height: 40px;
    font-size: 15px;
}

#logo_img {
    height: 60px;
    width: 50px;
    z-index: 2;
}

#logo {
    font-family: "Noto Serif Display", serif;
    font-weight: 400;
    font-size: 32px;
    padding-bottom: 5px;
    color: white;
    z-index: 2;
}

@media only screen and (max-width: 570px) {
    .logo {
        padding-left: 0vh !important;
        max-width: 80px;
        text-align: left;
        gap: 5px;
    }
    
    #logo {
        font-size: 25px;
    }

    #logo3 {
        font-size: 25px !important;
    }

    #logo_img {
        height: 50px !important;
        width: 50px !important;
    }
}

@media only screen and (max-width: 430px) {
    #logo {
        font-size: 18px;
    }

    #logo3 {
        font-size: 18px !important;
    }

    #logo_img {
        height: 32px;
        width: 30px;
    }
}

#logo3 {
    font-family: "Noto Serif Display", serif;
    font-weight: 400;
    font-size: 32px;
    padding-bottom: 5px;
    color: black;
    z-index: 2;
}

.logo {
    display: flex;
    align-items: center;
    padding-left: 3vh;
}

.logo > a {
    font-weight: 200;
}


/* making a nice animated nav bar */
/* ------------------------------------------------------------------------------------ */

.links {
    list-style: none;
    padding-right: 3vh;
    margin: 0;
    display: flex;
    /* text-transform: uppercase; */
    color: grey;
    z-index: 2;
  }
  .links a {
    padding: .5em .5em .25em;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: .3s;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
  }
  .links a:hover {
    color: #fff;
  }
  .links a:before {
    content: "";
    position: absolute;
    inset: calc(100% - 1.2px) 0 0 0; /* 3px = the thickness */
    background: white; /* the color */
    scale: 0 1;
    transition: .3s, translate 0s .3s;
  }
  .links:hover a:before {
    scale: 1;
  }
  .links a:hover:before {
    translate: 1;
    transition: .3s;
  }
  .links:hover a:has(~ a:hover):before {
    translate: 100% 0;
    transition: .2s .2s, scale 0s .4s;
  }
  .links:hover a:hover ~ a:before {
    translate: -100% 0;
    transition: .2s .2s, scale 0s .4s;
  }
  

  .links3 {
    list-style: none;
    padding-right: 3vh;
    margin: 0;
    display: flex;
    /* text-transform: uppercase; */
    color: rgb(207, 207, 207);
    z-index: 2;
}

.links3 > a {
    color: rgb(116, 116, 116);
}

.links3 a {
    padding: .5em .5em .25em;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: .3s;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}
.links3 a:hover {
    color: black;
}
.links3 a:before {
    content: "";
    position: absolute;
    inset: calc(100% - 1.2px) 0 0 0; /* 3px = the thickness */
    background: black; /* the color */
    scale: 0 1;
    transition: .3s, translate 0s .3s;
}
.links3:hover a:before {
    scale: 1;
}
.links3 a:hover:before {
    translate: 1;
    transition: .3s;
}
.links3:hover a:has(~ a:hover):before {
    translate: 100% 0;
    transition: .2s .2s, scale 0s .4s;
}
.links3:hover a:hover ~ a:before {
    translate: -100% 0;
    transition: .2s .2s, scale 0s .4s;
}



/* ------------------------------------------------------------------------------------ */

/* .links {
    z-index: 2;
    display: flex;
    gap: 20px;
    padding-right: 4vh;
}*/

.links > a {
    color: rgb(214, 214, 214);
    font-size: 16px;
}

.link:hover {
    cursor: pointer;
}

/* #links {
    font-size: 16px;
    transition: all .1s;
    color: rgb(222, 222, 222);

}

#links:hover {
    color: white;

} */

#contact-us-button {
    padding: 1vh;
    font-size: 16px;
    color: black;
    border: none;
    border-radius: 5px;
    background-color: #7CCCC7;
    /* background: linear-gradient(to bottom, rgba(122, 202, 194, 1), rgba(95, 192, 221, 0.4)); */
    /* border: solid; */
    border-width: 1px;
    border-color: #1bdcd2;
    border-radius: 5px;
    transition: all .5s;
}

#contact-us-button:hover {
    background-color: #8dd5d0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #EEEDEB; */
    /* background-color: white; */
    border-radius: 20px;
    /* position: fixed;
    width: 100%;
    z-index: 1;
    top: 78px; */

}

.header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #EEEDEB; */
    background-color: rgb(134, 133, 133);
    border-radius: 20px;
    border-color: #0C3557;
    border-style: solid;
    border-width: 1px;
}

.menu-button {
    display: none;
}

@media only screen and (max-width: 1170px){
    .links {
        display: none;
    }

    .links3 {
        display: none;
    }

    .menu-button {
        display: flex;
        visibility: visible;
        padding-right: 7vh;
    }

    .menu-button2 {
        display: flex;
        visibility: visible;
    }
}