.page1-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50vh;

}

.about-us-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 7vh;
    position: relative;
}


@media only screen and (max-width: 430px) {
    .about-us-container {
        padding: 2vh;
    }
}


@media only screen and (min-width: 1200px) {
    .about-us-container {
        align-items: center;
        
    }

    .large-monitor {
        width: 1000px;
    }
}