@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

hr {
    border: solid;
    border-width: .8px;
    width: 100%;
    border-color: rgb(138, 138, 138);
    opacity: .5;
    z-index: 3;
    margin-bottom: -4px;
    margin-top: -2px;

}

button {
    border: none;
    padding: 2vh 3vh 2vh 3vh;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #7CCCC7;
    /* background: linear-gradient(to bottom, rgba(122, 202, 194, 1), rgba(95, 192, 221, 0.4)); */
    /* border: solid; */
    border-width: 1px;
    border-color: #048982;
    border-radius: 5px;
    transition: all .5s;
    /* border-radius: 15px; */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    color: black;
}

button:hover {
    cursor: pointer;
    background-color: #8dd5d0;
    /* background: linear-gradient(to bottom, rgba(151, 233, 225, 0.9), rgba(40, 101, 120, 0.4)); */
}

.home-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #EEEDEA;
}

.header-text {
    background-image: url('../images/hero.jpg');
    background-size: cover;
    padding-bottom: 20vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;

}

@media only screen and (max-width: 430px) {
    .header-text {
        padding-bottom: 8vh;
        background-position-x: -120px;
    }
    
}

.header-text::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.18); 
    transition: all 0.28s ease-in-out;
    z-index: 0; 
}

.header-text-h1 {
    padding: 15vh 0vh 3vh 7vh;
    max-width: 50%;
    z-index: 2;
    text-align: left;
}

@media only screen and (max-width: 780px) {
    .header-text-h1 {
        padding: 15vh 0vh 3vh 7vh;
        max-width: 80%;
        font-size: 32px;
    }

    #header-text-h1 {
        font-family: "Manrope", sans-serif;
        font-weight: 100;
        font-size: 32px;
        color: white;
    }

    .background2::before {
        bottom: 70vh;
    }
}

@media only screen and (max-width: 570px) {
    .header-text-h1 {
        padding: 15vh 0vh 3vh 2vh;
    }
}

@media only screen and (max-width: 430px) {
    .header-text-h1 {
        padding: 10vh 0vh 3vh 2vh;
    }

    .header-text::before {
        background: rgba(0, 0, 0, 0.38); 
    }

    #header-text-h1 {
        font-family: "Manrope", sans-serif;
        font-weight: 100;
        font-size: 22px;
        color: white;
    }
}

.header-text-h1 > h1 {
    font-family: "Manrope", sans-serif;
    font-weight: 200;
    font-size: 42px;
    color: white;
}


.hero {
    padding: 3vh;
}

#hero {
    width: 800px;
}

.body-section {
    background-color: #F5F4F0;
    display: flex;
    justify-content: center;
    background-image: url('../images/spine2.png');
    background-size: cover;
    background-position-x: -650px;
    background-repeat: no-repeat;
    color: black;
}

.body-section-paragraph {
    padding: 15vh 7vh 15vh 7vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
    text-align: center;
    padding-left: 50%;
}

@media only screen and (max-width: 450px) {
    .body-section-paragraph {
        padding: 0vh;
        max-width: 300px !important;
    }
}

.body-section2-container {
    display: flex;
    justify-content: center;
    background-image: url('../images/line.png');
    background-size: cover;
    padding: 7vh;
}

@media only screen and (max-width: 450px) {
    .body-section2-container {
        padding: 0vh;
    }

    .body-section2 {
        padding: 0vh !important;
    }

    .body-section2 {
        max-width: 100% !important;
    }
}

.body-section3 {
    padding: 2vh 7vh 7vh 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    text-align: center;
}

.body-section3-container {
    display: flex;
    justify-content: center;
    padding: 7vh;
    background-color: white;
    align-items: center;
}

@media only screen and (max-width: 450px) {
    .body-section3-container {
        flex-direction: column-reverse;
        padding: 5vh 2vh 2vh 2vh;
    }

    .body-section {
        background-position-x: -590px;
    }

    .body-section-paragraph {
        color: white;
    }

    .eval {
        width: 100% !important;
    }

    #eval {
        width: 348px !important;
    }

    #body-section2-h1 {
        max-width: 300px !important;
    }
}

@media only screen and (max-width: 884px) {
    .body-section3-container {
        flex-direction: column-reverse;
    }

    .body-section3 {
        padding: 0vh;
    }

    .eval {
        display: flex;
        justify-content: center;
        width: 100% !important;
    }

}

@media only screen and (max-width: 600px) {
    .body-section3-container {
        padding: 2vh;
    }

}

@media only screen and (max-width: 1050px) {
    .body-section-paragraph {
        color: white;
        padding: 15vh 7vh 15vh 7vh;

    }

    .body-section {
        background-color: #a8a8a5;
    }
}

@media only screen and (max-width: 600px) {
    .body-section-paragraph {
        color: white;
        padding: 12vh 2vh 12vh 2vh;

    }

}

.eval {
    width: 50%;
}

#eval {
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    width: 400px;
}

.body-section2 {
    padding: 2vh 7vh 7vh 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .body-section2 {
        padding: 0vh;
        max-width: 100%;
    }

    .body-section2-container {
        padding: 2vh;
    }

    #body-section2-h1 {
        width: 100% !important;
    }

}

#body-section2-h1 {
    width: 550px;
}

#subheader {
    color: #165990;
    margin-top: -20px;
    font-weight: 800;
}

h3 {
    font-weight: 300;
}

.body-picture {
    /* background-image: url('../images/hero.jpg');
    background-size:cover; */
    display: flex;
    flex-direction: column;
    padding-bottom: 50vh;
    text-align: left;
}

.body-picture-paragraph {
    padding: 7vh 55% 7vh 7vh;
}


.reviews {
    padding: 12vh 7vh 12vh 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../images/lines4.png');
    background-size: cover;
}

.reviews2 {
    display: flex;
    justify-content: space-between;

}

@media only screen and (max-width: 1000px) {
    .reviews-2 {
        padding-left: 0px !important;
    }
}

@media only screen and (max-width: 780px) {
    .reviews {
        padding-top: 2vh;
    }

    .reviews2 {
        flex-direction: column;
    }

    .reviews-2 {
        text-align: center;
        max-width: 100% !important;
    }

    .reviews-1 {
        padding-top: 3vh;
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .reviews {
        padding: 2vh;
    }

}

@media only screen and (max-width: 430px) {
    .reviews {
        padding: 2vh 2vh 2vh 2vh;
    }

    .reviews-1 {
        padding-top: 3vh;
        max-width: 100% !important;
    }

    .reviews-2 {
        padding-left: 0px !important;
        max-width: 100% !important;
    }
    
    .reviews2 {
        flex-direction: column;
    }

    .footer-app-img > h1 {
        font-size: 40px;
    }

    .footer-app-img {
        padding: 2vh 2vh 15vh 2vh;
    }
}

#review-image {
    width: 500px;
}

.reviews-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50%;
    gap: 15px;
}

.reviews-2 {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    max-width: 40%;
}

#more-reviews {
    width: 200px;
}

.article-card {
    display: flex;
    /* max-height: 148px;
    min-height: 148px;
    max-width: 500px;
    min-width: 500px; */
    background-color: white;
    border-radius: 15px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.article-body {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    padding: 2vh 2vh 2vh 2vh;
}

.article-author {
    display: flex;
    align-items: center;
    gap: 12px;
}

.article-author-body {
    display: flex;
    align-items: center;
    text-align: right;
    gap: 10px;
}

.article-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1px;
    width: 1px;
    color: white;
    padding: 2vh;
    background-color: green;
    border-radius: 50px;
}

#quote {
    color: #165990;
}

.make-appointment-container {
    position: relative;
    background-color: white;
}

.custom-shape-divider-bottom-1722894737 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1722894737 svg {
    position: relative;
    display: block;
    width: calc(300% + 1.3px);
    height: 137px;
}

.custom-shape-divider-bottom-1722894737 .shape-fill {
    fill: #f5f4f0;
}



/* for the heroimage */

.custom-shape-divider-bottom-1722971431 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: 2;
}

@media only screen and (max-width: 430px) {
    .custom-shape-divider-bottom-1722971431 {
        margin-bottom: -1px;
    }
}

.custom-shape-divider-bottom-1722971431 svg {
    position: relative;
    display: block;
    width: calc(179% + 1.3px);
    height: 79px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1722971431 .shape-fill {
    fill: #FFFFFF;
}