#footer-app-img {
    
}

.footer-app-img {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image: url('../images/appointment.jpg');
    background-size: cover; */
    padding: 6vh 15vh 17vh 15vh;
}

.footer-app-img > h1 {
    color: black;
    font-size: 50px;
}

#footer-app-img-button {
    width: 200px;
}

.footer-background {
    /* display: flex;
    justify-content: center; */
    background-color: #F5F4F0;
    
}

.footer-body {
    display: flex;
    /* align-items: flex-start; */
    justify-content: center;
    padding: 7vh;
    background-color: #F5F4F0;
    text-align: center;
    gap: 50px;
    
    /* flex-direction: column; */
}

/* @media only screen and (min-width: 1200px) {
    .footer-body {
        width: 1025px;
    }
    
    .footer-background {
        justify-content: center;
    }
} */

.footer-body2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0vh 7vh 7vh 7vh;
    background-color: #F5F4F0;
    text-align: center;
    gap: 50px;
    /* flex-direction: column; */
}

.footer-logo {
    display: flex;
    text-align: left;
}


.services-footer {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 230px;
}

.services-footer > a {
    color: rgb(102, 102, 102);
    font-size: 16px;
}

#logo_img_footer {
    width: 100px;
}

.footer-contact {
    text-align: left;
}

.footer-contact > h4 {
    color: rgb(102, 102, 102);
    font-family: "Manrope", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.footer-body > h1 {
    font-family: "Noto Serif Display", serif;
    font-size: 25px;
}

.footer-body > h4 {
    font-family: "Noto Serif Display", serif;
    font-weight: 200;
}

.footer-contact > h1 {
    font-size: 24px;
}

.services-footer > h1 {
    font-size: 24px;
}

.footer-map > h1 {
    font-size: 24px;
}

#footer-a {
    font-family: "Manrope", sans-serif;
    font-weight: 300;
}

#footer-a:hover {
    color: black;
}

.footer-make-appointment {
    border: solid;
    padding: 10vh;
    border-radius: 10px;
    background-color: rgb(241, 238, 238);
}

.footer-map {
    text-align: left;
    width: 800px;
}

@media only screen and (min-width: 1200px) {
    .footer-body {
        width: 1000px;

    }

    .footer-background {
        display: flex;
        justify-content: center;
        background-color: #F5F4F0;
        
    }
}


@media only screen and (max-width: 430px) {
    .footer-body2 {
        flex-direction: column;
        padding: 0vh 2vh 2vh 2vh;

        gap: 0px;
    }

    .footer-body {
        flex-direction: column;
        padding: 0vh 2vh 2vh 2vh;
        gap: 0px;
    }

    .footer-map {
        width: 100%;
    }
}

.footer-contact3 {
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width: 830px) {
    .footer-body2 {
        flex-direction: column;
        padding: 0vh 7vh 2vh 7vh;

        gap: 0px;
    }

    .footer-body {
        flex-direction: column;
        padding: 0vh 7vh 2vh 7vh;

        gap: 0px;
    }

    .footer-contact3 {
        display: flex;
        /* flex-direction: row; */
        /* gap: 50px; */
    }

    .footer-map {
        width: 100%;
    }

}

@media only screen and (max-width: 600px) {
    .footer-body2 {
        flex-direction: column;
        padding: 0vh 2vh 2vh 2vh;
        gap: 0px;
    }

    .footer-body {
        flex-direction: column;
        padding: 0vh 2vh 2vh 2vh;
        gap: 0px;
    }

    

}