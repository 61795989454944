.new-patients-container {
    display: flex;
    flex-direction: column;
    padding: 7vh;
}

.new-patient-buttons {
    display: flex;
    gap: 20px;
}

@media only screen and (max-width: 430px) {
    .new-patients-container {
        padding: 2vh;
    }
}

@media only screen and (min-width: 1200px) {
    .new-patients-container {
        align-items: center;
        
    }

    .large-monitor {
        width: 1000px;
    }
}