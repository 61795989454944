.doctors-header {
    /* display: flex; */
    text-align: center;
    padding-top: 7vh;
}

.meet-our-doctors-container {
    display: flex;  
    justify-content: center;
    gap: 150px; 
    padding: 7vh;
    /* justify-content: space-around; */
}

.image-wrap {
    /* text-wrap: wrap; */
}

#amos {
    /* float: right; */
    height: 300px;
    width: 300px;
    object-fit: cover;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
    /* border-top-right-radius: 100px; */
    border-radius: 10px;
    transition: all .5s;
}

#amos:hover {
    box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.3);
}

#title {
    font-size: 20px;
}

.title {
    display: flex;
    justify-content: flex-start;
    text-align: left;
}

.doctors {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    object-fit: contain;
    text-align: left;
}

@media only screen and (max-width: 430px) {
    .meet-our-doctors-container {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        padding: 2vh;
    }
}