.dramoschon {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 7vh;
}

@media only screen and (max-width: 430px) {
    .dramoschon {
        padding: 2vh;
    }
}

@media only screen and (min-width: 1200px) {
    .dramoschon {
        align-items: center;
        
    }

    .large-monitor {
        width: 1000px;
    }
}