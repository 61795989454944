.services-header {
    text-align: center;
    padding-top: 7vh;
}

.our-services-container {
    padding: 0vh;
}

.our-services-highlight-box {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    align-content: space-evenly;
    /* justify-content: space-evenly; */
    text-align: center;
    grid-gap: 10px;
    padding: 2vh;
}


.chiropractic-care {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh;
    background-image: url('../images/highlight/care.jpg');
    background-size: cover;
    
}


.auto-accident-chiropractic {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh;
    background-image: url('../images/highlight/auto.jpg');
    background-size: cover;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    /* grid-row-end: 5; */
}

.contact-us-services {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh;
    height: 95px;
    background-image: url('../images/appointment.jpg');
    background-size: cover;
}

    /* padding: 10vh;
    background-image: url('../images/highlight/auto.jpg');
    background-size: cover;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3; */

/* Common styling for all classes */
.chiropractic-care,
.auto-accident-chiropractic,
.sports-chiropractic,
.pregnancy-chiropractic,
.pediatric-chiropractic,
.senior-chiropractic,
.personal-injury,
.klaser-treatment,
.contact-us-services {
    position: relative;
    display: grid;
    grid-template-columns: subgrid;
    align-items: center;
    justify-content: center;
    padding: 10vh;
    background-size: cover;
    transition: all 0.35s ease-in-out;
    overflow: hidden; /* Ensure the pseudo-element does not overflow */
    border-radius: 15px;
}

/* Dark overlay pseudo-element */
.chiropractic-care::before,
.auto-accident-chiropractic::before,
.sports-chiropractic::before,
.pregnancy-chiropractic::before,
.pediatric-chiropractic::before,
.senior-chiropractic::before,
.personal-injury::before,
.klaser-treatment::before,
.contact-us-services::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55); /* Dark overlay */
    transition: all 0.28s ease-in-out;
    z-index: 1; /* Ensure it is below the h3 element */
}

/* Lighter overlay on hover */
.chiropractic-care:hover::before,
.auto-accident-chiropractic:hover::before,
.sports-chiropractic:hover::before,
.pregnancy-chiropractic:hover::before,
.pediatric-chiropractic:hover::before,
.senior-chiropractic:hover::before,
.personal-injury:hover::before,
.klaser-treatment:hover::before,
.contact-us-services:hover::before {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

/* Styling for the h3 element */
.chiropractic-care > h3,
.auto-accident-chiropractic > h3,
.sports-chiropractic > h3,
.pregnancy-chiropractic > h3,
.pediatric-chiropractic > h3,
.senior-chiropractic > h3,
.personal-injury > h3,
.klaser-treatment > h3,
.contact-us-services > h3 {
    position: relative;
    z-index: 2; /* Ensure it is above the pseudo-element */
    font-size: 20px;
    font-weight: 800;
    color: white;
}

.chiropractic-care > h3:hover,
.auto-accident-chiropractic > h3:hover,
.sports-chiropractic > h3:hover,
.pregnancy-chiropractic > h3:hover,
.pediatric-chiropractic > h3:hover,
.senior-chiropractic > h3:hover,
.personal-injury > h3:hover,
.klaser-treatment > h3:hover {
    cursor: pointer;
}

.klaser-treatment {
    padding: 10vh;
    background-image: url('../images/highlight/laser.jpg');
    background-size: cover;
}


.sports-chiropractic {
    padding: 10vh;
    background-image: url('../images/highlight/sports.webp');
    background-size: cover;
}

.pregnancy-chiropractic {
    padding: 10vh;
    background-image: url('../images/highlight/pregnancy.png');
    background-size: cover;
}

.pediatric-chiropractic {
    padding: 10vh;
    background-image: url('../images/highlight/peds.jpg');
    background-size: cover;
}

.senior-chiropractic {
    padding: 10vh;
    background-image: url('../images/highlight/elderly.png');
    /* background-size: auto; */
    object-fit: contain;
}

.personal-injury {
    padding: 10vh;
    background-image: url('../images/highlight/personal.png');
    background-size: cover;
}

@media only screen and (max-width: 430px) {
    .services-header {
        padding: 2vh;
    }
}


@media only screen and (max-width: 970px){
    .our-services-highlight-box {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        align-content: space-evenly;
        /* justify-content: space-evenly; */
        text-align: center;
    }

    .contact-us-services {
        display: none;
    }

}

@media only screen and (max-width: 620px){
    .our-services-highlight-box {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        align-content: space-evenly;
        /* justify-content: space-evenly; */
        text-align: center;
    }

    .contact-us-services {
        display: flex;
    }

}
