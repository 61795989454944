#laser-poster {
    width: 500px;
}

.laser {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#laser {
    width: 500px;
}

#services-images {
    /* width: 500px; */
}