.services-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 7vh;
}

.appointment-today {
    display: flex;
    justify-content: center;
    padding-top: 3vh;
}

#services-images {
    width: 100%;
}

@media only screen and (max-width: 430px) {
    .services-container {
        padding: 2vh !important;
    }
}

@media only screen and (min-width: 1200px) {
    .services-container {
        align-items: center;
        
    }

    .large-monitor {
        width: 1000px;
    }
}